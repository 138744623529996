import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import {GoogleOAuthProvider} from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById("root"));
let is_dev = process.env.NODE_ENV
is_dev = is_dev && is_dev === 'development'

const app = (
    <GoogleOAuthProvider clientId='349336373903-7e6dk3eeos532v83etoj9fublfnf27uq.apps.googleusercontent.com'>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </GoogleOAuthProvider>
);

const rootComponent = is_dev ? (app) : (
    <React.StrictMode>
        {app}
    </React.StrictMode>
);

root.render(rootComponent);
reportWebVitals();
