import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import { getInvalidCallbacks, getPointsHistory, getUserActivity } from '../../api';
import styles from '../../component.module.scss';
import HeaderTheme from '../Header/HeaderTheme';

function PointsHistory() {
    const navigate = useNavigate();
    const [pointHistory, setPointHistory] = useState([]);
    const [invalidCallBacks, setInvalidCallBacks] = useState([]);
    const [userActivity, setUserActivity] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Promise.all([getPointsHistory(), getInvalidCallbacks(), getUserActivity()])
            .then(([pointsHistoryData, invalidCallbacksData, userActivityData]) => {
                setPointHistory(pointsHistoryData.data);
                setInvalidCallBacks(invalidCallbacksData.data);
                setUserActivity(userActivityData.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);

    // Define columns for Points History
    const columns = [
        { name: 'id', selector: 'id', sortable: true },
        { name: 'Date', selector: 'date', sortable: true },
        { name: 'Ad Network', selector: 'ad_network_name', sortable: true },
        { name: 'Offer Name', selector: 'offer.offer_name', sortable: true, cell: (row) => (row.offer && row.offer.offer_name ? row.offer.offer_name : 'N/A') },
        { name: 'Points Earned', selector: 'points_earned', sortable: true },
    ];

    // Points History
    const rows = pointHistory.map((entry) => ({
        id: entry.id,
        date: new Date(entry.timestamp).toLocaleDateString(),
        ad_network_name: entry.ad_network_name,
        offer: entry.offer,
        points_earned: entry.points_earned,
    }));

    // Invalid Callbacks
    const invalidCallbacksColumns = [
        { name: 'id', selector: 'id', sortable: true },
        { name: 'Date', selector: 'timestamp', sortable: true },
        { name: 'Ad network', selector: 'ad_network_name', sortable: true },
        { name: 'Offer Name', selector: 'offer.offer_name', sortable: true, cell: (row) => (row.offer && row.offer.offer_name ? row.offer.offer_name : 'N/A') },
        { name: 'Reason', selector: 'reason', sortable: true },
    ];

    // Map rows for Invalid Callbacks (example, adjust according to your data)
    const invalidCallbacksRows = invalidCallBacks.map((entry) => ({
        id: entry.id,
        timestamp: new Date(entry.timestamp).toLocaleDateString(),
        ad_network_name: entry.ad_network_name,
        offer: entry.offer,
        reason: entry.reason,
    }));

    // Define columns for User Activity
    const userActivityColumns = [
        { name: 'id', selector: 'id', sortable: true },
        { name: 'Date', selector: 'timestamp', sortable: true },
        { name: 'Activity Type', selector: 'activity_type', sortable: true },
        { name: 'Ip Address', selector: 'ip', sortable: true },
    ];

    const userActivityRows = userActivity.map((entry) => ({
        id: entry.id,
        timestamp: new Date(entry.timestamp).toLocaleDateString(),
        activity_type: entry.activity_type,
        ip: entry.ip,
    }));

    // Custom styles for DataTable
    const customStyles = {
        title: {
            style: {
                fontSize: '24px', 
                color: 'black', 
                textTransform: 'uppercase', // Apply text transformation if needed
                fontWeight: 'bold', // Set the font weight
                letterSpacing: '2px', // Set letter spacing
            },
        },
        headRow: {
            style: {
                backgroundColor: "#F5F5F5", // Set background color for head row
            },
        },
        headCells: {
            style: {
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#2c3e50', // Head cell text color
            },
        },
        rows: {
            style: {
                fontSize: '14px',
                textAlign: 'center',
            },
        },
        table: {
            style: {
                width: '90%', // Adjust the width here
                border: '3px solid #77dd77', // Add the border style
                margin: '0 auto', // Center the table horizontally
            },
        },
        pagination: {
            style: {
                width: '92%', // Set the width here to match the table
                margin: '0 auto', // Center the pagination horizontally
            },
        },
    };

    return (
        <>
            <HeaderTheme />

            {/* Loader */}
            {loading && (
                <div className='text-center mt-5'>
                    <Spin color='secondary' size='large' />
                </div>
            )}

            {/* Points History */}
            {!loading && (
                <div className={styles['dataTable-wrapper']}>
                    <DataTable
                        title='Points History'
                        columns={columns}
                        data={rows}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        highlightOnHover
                        pointerOnHover
                        onRowClicked={(row) => navigate(`/dashboard/?id=${row.id}`)}
                        customStyles={customStyles}
                    />
                </div>
            )}

            {/* Invalid Callbacks */}
            {!loading && (
                <div className={styles['dataTable-wrapper']}>
                    <DataTable
                        title='Invalid Callbacks'
                        columns={invalidCallbacksColumns}
                        data={invalidCallbacksRows}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        highlightOnHover
                        pointerOnHover
                        onRowClicked={(row) => navigate(`/dashboard/?id=${row.id}`)}
                        customStyles={customStyles}
                    />
                </div>
            )}

            {/* User Activity */}
            {!loading && (
                <div className={styles['dataTable-wrapper']}>
                    <DataTable
                        title='User Activity'
                        columns={userActivityColumns}
                        data={userActivityRows}
                        pagination
                        paginationPerPage={10}
                        paginationRowsPerPageOptions={[5, 10, 15, 20]}
                        highlightOnHover
                        pointerOnHover
                        onRowClicked={(row) => navigate(`/dashboard/?id=${row.id}`)}
                        customStyles={customStyles}
                    />
                </div>
            )}
        </>
    );
}

export default PointsHistory;
