import React, {useEffect, useState} from "react";
import {Container, Divider, Typography} from "@mui/material";
import "./CpaLead.scss";
import CardList from "../../components/CardList/CardList";
import MessageModal from "../../common/MessageModal";
import {fetchOffers, getUserIP} from "../../api";

const CpaLead = () => {

    const [offers, setOffers] = useState([]);

    const [isErrorModal, setIsErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    function showError(error, extendedMessage = '') {
        const message = error?.response?.data?.message || error.message + extendedMessage
        setErrorMessage(message);
        setIsErrorModal(true);
    }

    useEffect(() => {
        getUserIP().then((location) => {
            fetchOffers(location, 'cpalead', showError).then((_offers) => {
                setOffers(_offers)
            }).catch((error) => {
                showError(error);
            });
        }).catch((error) => {
            showError(error, ". Unable to get user IP. Please try again later.");
        });
    }, []);

    return (
        <>
            {
                offers.length > 0 ? (
                    <Container>
                        <Typography variant="body1" fontWeight="bolder" color="initial" marginBottom={"10px"}>
                            CPA Lead Offers
                        </Typography>
                        <CardList offers={offers}/>
                    </Container>
                ) : <Divider style={{margin: '16px 0'}}/>
            }
            <MessageModal
                open={isErrorModal}
                error
                onClose={setIsErrorModal}
                title={"Error"}
                content={errorMessage}
                timeOut={10000}
            />
        </>
    );
};

export default CpaLead;
