import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import HeaderTheme from "../../components/Header/HeaderTheme";
import { Card, Button, Typography, List, ListItem, ListItemText, CardContent, Grid } from '@mui/material';

const Redeem = () => {
  const [userPoint, setUserPoints] = useState(0)
  const fetchUserDetails = (token) => {
    const apiUrl = "https://swagkash.com/api/v1/account/user_detail/";
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.points) {
          setUserPoints(parseFloat(data.points).toFixed(2));
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    fetchUserDetails(storedToken);
  }, [])

  //points data:::
  const [gifts, setGifts] = useState([
    { name: 'iTunes', amount: '$5', points: 1800 },
    { name: 'iTunes', amount: '$10', points: 2800 },
    { name: 'iTunes', amount: '$15', points: 4300 },
    { name: 'iTunes', amount: '$25', points: 7000 },
    { name: 'Google Play', amount: '$10', points: 3000 },
    { name: 'Amazon', amount: '$5', points: 1500 },
    { name: 'Amazon', amount: '$10', points: 2800 },
    { name: 'Best Buy', amount: '$5', points: 1500 },
    { name: 'Steam Gift Card', amount: '$5', points: 1800 },
    { name: 'G2A Code (Euro Only)', amount: '5€', points: '' },//random points 
    { name: 'Sony Playstation', amount: '$10', points: 2800 },
    { name: 'Steam Gift Card', amount: '$10', points: 2800 },
    { name: 'Rixty Gift Card', amount: '$10', points: 2800 },
    { name: 'Xbox Digital Gift Card', amount: '$15', points: 4300 },
    { name: 'Trion Defiance Gift Card', amount: '$15', points: 4300 },
    { name: 'Sony Playstation', amount: '$20', points: 5500 },
    { name: 'eBay Gift Card', amount: '$5', points: 1500 },
    { name: 'Applebee\'s Gift Card', amount: '$5', points: 1500 },
    { name: 'Columbia Sports Gift Card', amount: '$5', points: 1500 },
    { name: 'Walmart eGift Card', amount: '$5', points: 1500 },
    { name: 'Target eGift Card', amount: '$5', points: 1500 },
    { name: 'Sears Gift Card', amount: '$10', points: '' },//random points 
    { name: 'Old Navy eGift Card', amount: '$10', points: '' },//random points 
    { name: 'Groupon Gift Card', amount: '$10', points: 2800 },
    { name: 'Target eGift Card', amount: '$10',  points: '' },//random points 
    { name: 'GAP eGift Card', amount: '$10',  points: '' },//random points 
    { name: 'Visa eGift Card', amount: '$5', points: 1500 },
    { name: 'Visa eGift Card', amount: '$10', points: 2800 },
    { name: 'Visa eGift Card', amount: '$25', points: 6500 },
    { name: 'MC eGift Card', amount: '$5', points: 1500 },
    { name: 'MC eGift Card', amount: '$10', points: 2800 },
    { name: 'MC eGift Card', amount: '$25', points: 6500 }, 
    // bitcoins 
    { name: 'Bitcoin(Coinbase)', amount: '$5', points: 1500 }, 
    { name: 'Bitcoin(Non-Coinbase)', amount: '$5', points: 1800 }, 
    { name: 'Bitcoin(Coinbase)', amount: '$10', points: 2800 }, 
    { name: 'Bitcoin(Non-Coinbase)', amount: '$10', points: 3000 }, 
    { name: 'Bitcoin(coinbase)', amount: '$25', points: 6500 }, 
    { name: 'Bitcoin(Non-Coinbase)', amount: '$25', points: 7000 }, 
    { name: 'Bitcoin(Coinbase)', amount: '$50', points: 13500 }, 
    { name: 'Bitcoin(Non-Coinbase)', amount: '$50', points: 13500 }, 
  ]);

  const handleRedeem = (gift) => {
    // Implement the redeem logic here
    console.log(`Redeeming ${gift.name} for ${gift.points} points`);
  };
  return (
    <>
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <HeaderTheme />
      </div>
      <div className='row'>
        <div className="col-lg-2 ">
          <div className="ms-3 me-3  mt-4">
            <button className="btn btn-success btn-points w-100">
              <span style={{ fontWeight: 600 }}>{userPoint} Points</span>
            </button>
            <div
              className="accordion accordion-flush mt-2"
              id="accordionFlushExample"
            >
              <div className="accordion-item ">
                <h2 className="accordion-header " id="flush-headingOne">
                  <Link to="/dashboard" style={{ textDecoration: 'none' }}>
                    <button className="btn btn-success btn-points text-center w-100 text-black"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne">
                      <span style={{ fontWeight: 600 }}> Offer Walls </span>
                      {/* <ExpandMoreIcon /> */}
                    </button>
                  </Link>
                </h2>
              </div>
            </div>
            <div className="mt-4">
              <button className="btn btn-light w-100 text-dark mt-1">
                <i className="fa-solid fa-rectangle-list" />
                &nbsp; <span style={{ fontWeight: 500 }}> Surveys</span>
              </button>
              <button className="btn btn-light w-100 text-dark mt-1">
                <Link to='/redeem' style={{ textDecoration: 'none' }}>
                  <span style={{ fontWeight: '500', color: 'black' }}>
                    <i className="fa-solid fa-gift" />&nbsp; Redeem
                  </span>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className="col-lg-10">
          {/* Redeem Points Card */}
          <Card sx={{ mt: 4, p: 2, borderBottom: '1px solid #ddd' }}>
            <Typography variant="h4" style={{ fontWeight: "bolder", marginBottom: "16px" }}>Redeem</Typography>
            <Typography variant="body1" mb={2} style={{ marginBottom: "16px" }}>
            Redeem your points for free cash and digital gift cards.
            </Typography>
            {/* List of gifts with Redeem Points button */}
            <Grid container spacing={2}>
              {gifts.map((gift, index) => (
                <Grid item xs={12} key={index}>
                  <Card style={{ border: "none", borderBottom: "none" }}>
                    <CardContent>
                      <Grid container justifyContent="space-between">
                        <Grid item>
                          <Typography variant="h6" style={{ fontWeight: 'bold' }}>{gift.name}</Typography>
                          <Typography variant="body2" style={{ fontWeight: 'bold', color: '#666' }}>{gift.amount}</Typography>
                        </Grid>
                        <Grid item style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <Typography variant="body2" style={{ fontWeight: 'bold', textTransform: 'none', color: '#666' }}>
                            Points: {gift.points}
                          </Typography>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => handleRedeem(gift)}
                            style={{ textTransform: 'none', fontWeight: 'bold' }}
                            disabled={userPoint < gift.points}
                          >
                            <i className="fa-solid fa-gift" style={{ color: userPoint < gift.points ? "inherit" : "yellow"}}></i>
                            &nbsp;Redeem
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Card>
        </div>

      </div>
    </>

  )
}

export default Redeem