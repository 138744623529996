import React from "react";
import {Container, Grid} from '@mui/material';
import Card from '../Card/Card';

const CardList = ({ offers }) => {
    return (
        <Container>
            <Grid container spacing={3}>
                {offers.map((cardData, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4} lg={3}>
                        <Card {...cardData} />
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default CardList;
