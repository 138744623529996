import { createTheme, ThemeProvider } from '@mui/material/styles';
import ResponsiveAppBar from './ResponsiveAppBar';

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#ffffff',
    },
  },
});


const HeaderTheme = () => {
  return (
    <ThemeProvider theme={theme}>
      <ResponsiveAppBar />
    </ThemeProvider>
  );
};

export default HeaderTheme;