import React, {useState} from "react";
import "./Signup.scss";
import {Box, Container, Typography, Button, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router-dom";
import {useGoogleLogin} from "@react-oauth/google";
import {ReactComponent as GoogleIcon} from "../../assets/google-icon.svg";
import MessageModal from "../../common/MessageModal";
import logo from "../../assets/logo.png";
import axios from "axios";

const Signup = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [password, setPassword] = useState("");
    const [cpassword, setCpassword] = useState("");
    const [isCpasswordError, setIsCpasswordError] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const [googledata, setgoogledata] = useState({});

    // useEffect(() => {
    //     if (googledata?.access_token) {
    //         debugger
    //         axios
    //             .get(
    //                 `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googledata.access_token}`,
    //                 {
    //                     headers: {
    //                         Authorization: `Bearer ${googledata.access_token}`,
    //                         Accept: "application/json",
    //                     },
    //                 }
    //             )
    //             .then(async (res) => {
    //                 debugger
    //                 await axios
    //                     .post(`${process.env.REACT_APP_BASE_URL}/account/login/google/`, {
    //                         email: res.data.email,
    //                         gtype: true,
    //                     })
    //                     .then((res) => {
    //                         debugger
    //                         navigate("/signup-success/?email=" + res.data.email + "");
    //                     })
    //                     .catch(async (err) => {
    //                         setIsErrorModal(true);
    //                     });
    //             })
    //             .catch((err) => {
    //                 setIsErrorModal(true);
    //             });
    //     }
    // }, [googledata, navigate]);

    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/account/google/register/`, {
                    access_token: codeResponse.access_token,
                })
                .then((res) => {
                    localStorage.setItem("token", res.data.data.access);
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    navigate("/dashboard");
                })
                .catch(async (err) => {
                    let err_msg = err?.response?.data?.message?.errors?.[0]
                    let non_field_err = err?.response?.data?.message?.non_field_errors?.[0]
                    let message = err_msg || non_field_err || "Something Went Wrong";
                    setErrorMessage(message);
                    setIsErrorModal(true);
                });
        },
        onError: (error) => console.log("Signup Failed:", error),
    });
    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const submitData = async () => {
        if (email && isValidEmail(email) && password) {
            setLoading(true);
            let userIP = await axios.get(`https://api.ipify.org?format=json`).then(async (response) => {
                return response.data?.ip;
            })
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/account/register/`, {
                    email: email,
                    password: password,
                    confirm_password: cpassword,
                    ip: userIP
                })
                .then((res) => {
                    setLoading(false);
                    navigate("/signup/success");
                })
                .catch((err) => {
                    setLoading(false);
                    let response = err.response
                    let message = ''
                    if (response) {
                        let arr = response.data[0]
                        message = arr?.email[0] || response.data.email[0] || err.message
                    } else {
                        message = err.message
                    }
                    setErrorMessage(message);
                    setIsErrorModal(true);
                });
        }
    };

    const checkValidations = () => {
        if (!email || !isValidEmail(email)) {
            setIsEmailError(true);
        }

        if (!password || password.length < 8) {
            setIsPasswordError(true);
        } else {
            setIsPasswordError(false);
        }

        if (!cpassword || cpassword !== password) {
            setIsCpasswordError(true);
        }

        if (
            email &&
            password &&
            cpassword &&
            password === cpassword &&
            isValidEmail(email) &&
            password.length >= 8
        ) {
            //   submitData();
        }
    };

    return (
        <>
            <Box className="signup-wrapper">
                <Container
                    className="signup-screen-container"
                    maxWidth="md"
                    sx={{backgroundColor: "white"}}
                >
                    <Box className="signup-form-wrapper">
                        <Box
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <img
                                src={logo}
                                style={{
                                    width: "100px",
                                }}
                                alt=""
                                sizes=""
                                srcset=""
                            />
                        </Box>
                        <Typography className="signup-heading" variant="h4" color="initial">
                            Create a New Account
                        </Typography>
                        <Box mt={3}>
                            <Typography mb={1} variant="body1" color="initial">
                                Email
                            </Typography>
                            <TextField
                                className="signup-form-input"
                                id="outlined-basic"
                                label=""
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setIsEmailError(false);
                                }}
                                error={isEmailError}
                                helperText={isEmailError ? "Please enter a valid email" : ""}
                                placeholder="Enter your email"
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Box>
                        <Box mt={3}>
                            <Typography mb={1} variant="body1" color="initial">
                                Password
                            </Typography>
                            <TextField
                                className="signup-form-input"
                                id="outlined-basic"
                                label=""
                                placeholder="Enter your password"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setIsPasswordError(false);
                                }}
                                error={isPasswordError}
                                helperText={
                                    isPasswordError
                                        ? "Please enter a valid password. Password must have atleast 8 characters"
                                        : ""
                                }
                                variant="outlined"
                                size="small"
                                fullWidth
                            />
                        </Box>
                        <Box mt={3}>
                            <Typography mb={1} variant="body1" color="initial">
                                Confirm Password
                            </Typography>
                            <TextField
                                className="signup-form-input"
                                id="outlined-basic"
                                label=""
                                type="password"
                                placeholder="Confirm your password"
                                error={isCpasswordError}
                                helperText={
                                    isCpasswordError
                                        ? "Password and confirm password must match"
                                        : ""
                                }
                                variant="outlined"
                                value={cpassword}
                                onChange={(e) => {
                                    setCpassword(e.target.value);
                                    setIsCpasswordError(false);
                                }}
                                size="small"
                                fullWidth
                            />
                        </Box>
                        <Box mt={3}>
                            <LoadingButton
                                className={"signup-form-button"}
                                variant="contained"
                                onClick={() => {
                                    checkValidations();
                                    submitData();
                                }}
                                loading={loading}
                                size="medium"
                                fullWidth
                            >
                                Sign up
                            </LoadingButton>
                        </Box>

                        <Box mt={4}>
                            <Button
                                onClick={() => googleLogin()}
                                className="google-login-button"
                                variant="contained"
                                size="medium"
                                fullWidth
                            >
                                <GoogleIcon/> &nbsp; Sign up with Google
                            </Button>
                        </Box>

                        <Typography variant="body1" my={2} className="last-text" color="initial">
                            Have you already an account?{" "}
                            <span onClick={() => {
                                navigate("/login");
                            }}>Sign in</span>
                        </Typography>
                    </Box>
                </Container>
            </Box>
            <MessageModal
                open={isErrorModal}
                onClose={setIsErrorModal}
                error={true}
                title="Error"
                content={errorMessage}
            />
        </>
    );
};

export default Signup;
