import React from "react";
import { Box, Button, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router";
import logo from "../../assets/logo.png";
import "./Home.scss";

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box className="nav-wrapper">
        <Container className="nav-container custom-container">
          <Box className="logo">
            <img
              src={logo}
              alt=""
              srcset=""
              style={{
                width: "100px",
              }}
            />
          </Box>

          <ul>
            <li>
              <a href="#process">Process</a>
            </li>
            <li>
              <a href="#faqs">FAQs</a>
            </li>
            <li>
              <a href="#contact">Contact Us</a>
            </li>
          </ul>

          <Box className="btns">
            <Typography
              variant="body1"
              className="login-btn"
              onClick={() => navigate("/login")}
              color="initial"
            >
              Log in
            </Typography>

            <Button
              className="started-btn"
              onClick={() => navigate("/signup")}
              variant="contained"
              color="primary"
              style={{ color: "#36b44c", backgroundColor: "white" }}
            >
              Sign up
            </Button>
          </Box>
        </Container>
      </Box>
    </>

  );
};

export default Home;
