import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import {
  Container,
  Divider,
  Typography,
  Box,
  Button,
  CircularProgress,
} from "@mui/material";
import "./Dashboard.scss";
import HeaderTheme from "../../components/Header/HeaderTheme";
import OfferListing from "../../components/OfferListing/OfferListing";
import Logo from "./Assets/Logo.png";
import { Spin, Modal, Empty, Avatar } from "antd";
import {
  AllInclusiveOutlined,
  DesktopOutlined,
  MobileOutlined,
  AppleOutlined,
  AndroidOutlined,
} from "@ant-design/icons";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faList } from "@fortawesome/free-solid-svg-icons";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";
import offertoro from "./Assets/offertroo.png";
import adgem from "./Assets/adgem.png";
import Thoermreach from "./Assets/Thoermreach.png";
import cpagrip from "./Assets/cpagrip.jpeg";
import wannads from "./Assets/wannads.png";
import cpaLead from "./Assets/cpaLead.png";
import pollfish from "./Assets/pollfish.png";
import { fetchOffers, getUserIP } from "../../api";
import { fetchPopularOffers } from "../../api";
import { fetchAllOffers } from "../../api";
import { fetchTheoremReachSurvey } from "../../api";
import { Pagination } from "@mui/material"; //pageination 
const Dashboard = () => {

  const [userPoints, setUserPoints] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [offer_name, setOfferName] = useState("");
  const [offer_desc, setDescription] = useState("");
  const [modalUrl, setModalUrl] = useState("");
  const [modalAmount, setModalAmount] = useState(0);
  const [modaldisclaimer, setModalDisclaimer] = useState(
    "Click and view at least one circular. This offer can be completed an unlimited amount of times."
  );
  const [email, setEmail] = useState("");
  const [offers, setOffers] = useState([]);
  const [currentNetwork, setCurrentNetwork] = useState("offertoro");
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [device_type, setDeviceTypeFilter] = useState("all");

  // theoremReachIframeUrl:::
  const [theoremReachIframeUrl, setTheoremReachIframeUrl] = useState("");
  const apiKey = "ebd424bcbda9141349310695387b";
  const userId = JSON.parse(localStorage.getItem("user")).id;
  let userIpAddress = "";
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"))?.email;
    setEmail(user);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getUserIP()
      .then((location) => {
        if (device_type === "most-popular") {
          fetchPopularOffers()
            .then((_offers) => {

              setOffers(_offers);
              setIsLoading(false);
            })
            .catch((error) => {
              showError(error);
              setIsLoading(false);
            });
        } else if (device_type === "all") {
          fetchAllOffers(location, currentNetwork, showError)
            .then((_offers) => {
              setOffers(_offers);
              setIsLoading(false);
            })
            .catch((error) => {
              showError(error);
              setIsLoading(false);
            });
        } else {
          fetchOffers(location, currentNetwork, showError, device_type)
            .then((_offers) => {
              setOffers(_offers);
              setIsLoading(false);
            })
            .catch((error) => {
              showError(error);
              setIsLoading(false);
            });
        }
      })
      .catch((error) => {
        showError(error, ". Unable to get user IP. Please try again later.");
        setIsLoading(false);
      });
    const storedToken = localStorage.getItem("token");
    fetchUserDetails(storedToken);
  }, [currentNetwork, device_type]);

  const handleNetworkButtonClick = (networkName) => {
    setCurrentNetwork(networkName);
  };

  const showModal = (url, amount, disclaimer, offer_desc, offer_name) => {
    setModalUrl(url);
    setModalAmount(amount);
    setIsModalVisible(true);
    setModalDisclaimer(disclaimer);
    setDescription(offer_desc);
    setOfferName(offer_name);
  };

  const handleModalRedirect = () => {
    window.open(modalUrl, "_blank");
    setIsModalVisible(false);
  };

  function showError(error, extendedMessage = "") {
    const message =
      error?.response?.data?.message || error.message + extendedMessage;
    setErrorMessage(message);
    setIsErrorModal(true);
  }

  // fetch user Details :::
  const fetchUserDetails = (token) => {
    const apiUrl = "https://swagkash.com/api/v1/account/user_detail/";
    fetch(apiUrl, {
      method: "GET",
      headers: {
        Authorization: `JWT ${token}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.points) {
          setUserPoints(parseFloat(data.points).toFixed(2));
          console.log(data.points)
        }
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  };
  // thoerem reach
  async function fetchData() {
    try {
      const apiKey = "9f7787b11d56042e08f53cce6fde";
      const userId = JSON.parse(localStorage.getItem("user")).id;
      const userIpAddress = await getUserIP();
      const theoremReachData = await fetchTheoremReachSurvey(
        apiKey,
        userId,
        userIpAddress
      );
      const theoremReachIframeUrl = `https://theoremreach.com/respondent_entry/direct?api_key=${apiKey}&user_id=${userId}&transaction_id=${theoremReachData.transaction_id}`;
      setTheoremReachIframeUrl(theoremReachIframeUrl);
    } catch (error) {
      console.error("Error fetching TheoremReach Survey:", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [userId]);



  //offers walls cards ::
  const cardData = [
    { id: 1, imageSrc: offertoro, href: `https://www.offertoro.com/ifr/show/20063/${userId}/14718` },
    { id: 2, imageSrc: adgem, href: `https://api.adgem.com/v1/wall?appid=28217&playerid=${userId}` },
    { id: 3, imageSrc: cpaLead, href: `https://fastsvr.com/list/510342/${userId}` },
    { id: 4, imageSrc: wannads, href: `https://earn.wannads.com/wall?apiKey=64e7654296e89497609415&userId=${userId}` },
    { id: 5, imageSrc: cpagrip, href: `https://ridefiles.net/1582683/${userId}` },
    { id: 6, imageSrc: Thoermreach, href: theoremReachIframeUrl },
    { id: 7, imageSrc: pollfish, href: 'https://pollfish.netlify.app' },
  ];



  // fetch offers from cards at footers 
  const FetchOffersCardBtn = (networkName, href) => {
    if (href) {
      window.open(href, '_blank');
    }
  };

  // meterial ui pagination
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 18;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentOffers = offers.slice(indexOfFirstItem, indexOfLastItem);

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <div style={{ position: 'sticky', top: 0, zIndex: 1000 }}>
        <HeaderTheme />
      </div>

      <div>
        {isLoading ? (
          <div className="loader-container">
            <Spin size="large" />
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-2 ">
              <div className="ms-3 me-3  mt-4">
                <button className="btn btn-success btn-points w-100">
                  <span style={{ fontWeight: 600 }}>{userPoints} Points</span>
                </button>
                <div
                  className="accordion accordion-flush mt-2"
                  id="accordionFlushExample"
                >
                  <div className="accordion-item ">
                    <h2 className="accordion-header " id="flush-headingOne">
                      <button className="btn btn-success btn-points text-center w-100 text-black"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapseOne"
                        aria-expanded="false"
                        aria-controls="flush-collapseOne">
                        <span style={{ fontWeight: 600 }}> Offer Walls </span>
                        <ExpandMoreIcon />
                      </button>
                    </h2>
                    <div
                      id="flush-collapseOne"
                      className="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div className="accordion-body text-center">
                        <button
                          className="your-account-button"
                          onClick={() => handleNetworkButtonClick("offertoro")}
                        >
                          Offer Toro
                        </button>
                        <button
                          className="your-account-button"
                          onClick={() => handleNetworkButtonClick("adgem")}
                        >
                          Ad Gem
                        </button>
                        <button
                          className="your-account-button"
                          onClick={() => handleNetworkButtonClick("cpagrip")}
                        >
                          CPA Grip
                        </button>
                        <button
                          className="your-account-button"
                          onClick={() => handleNetworkButtonClick("cpalead")}
                        >
                          CPA Lead
                        </button>
                        <button
                          className="your-account-button"
                          onClick={() => handleNetworkButtonClick("wannads")}
                        >
                          Wannads
                        </button>
                        <button
                          className="your-account-button"
                          onClick={() =>
                            handleNetworkButtonClick("ayetstudios")
                          }
                        >
                          Ayet-studios
                        </button>
                        <a
                          className="your-account-button"
                          href={"https://pollfish.netlify.app/"}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Pollfish
                        </a>
                        <a
                          className="your-account-button"
                          href={`${theoremReachIframeUrl}`}
                          target="_blank"
                          style={{ textDecoration: "none" }}
                        >
                          Theorem Reach
                        </a>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <button className="btn btn-light w-100 text-dark mt-1">
                    <i className="fa-solid fa-rectangle-list" />
                    &nbsp; <span style={{ fontWeight: 500 }}> Surveys</span>
                  </button>
                  <button className="btn btn-light w-100 text-dark mt-1">
                    <Link to='/redeem' style={{ textDecoration: 'none' }}>
                      <span style={{ fontWeight: '500', color: 'black' }}>
                        <i className="fa-solid fa-gift" />&nbsp; Redeem
                      </span>
                    </Link>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="mt-5">
                <p className="main-text mb-0">
                  Earn Offers for Cash
                  {/* {device_type === 'most-popular'
                    ? 'Most Popular'
                    : currentNetwork
                      ? `${currentNetwork.charAt(0).toUpperCase()}${currentNetwork.slice(1)} Offers`
                      : ''} */}
                </p>
                <p className="mt-0" style={{ fontSize: "12px" }}>
                  Complete offers and task
                </p>
                <div className="d-flex">
                  <button
                    className={`btn btn-warning pt-2 pb-2 me-2 ${device_type === "all" ? "active" : ""
                      }`}
                    style={{
                      border:
                        device_type === "all"
                          ? "3px solid #5bbf6d"
                          : "2px solid transparent",
                    }}
                    onClick={() => {
                      setDeviceTypeFilter("all");
                    }}
                  >
                    <FontAwesomeIcon icon={faList} /> All
                  </button>
                  <button
                    className={`btn btn-warning pt-2 pb-2 me-2 ${device_type === "desktop" ? "active" : ""
                      }`}
                    style={{
                      border:
                        device_type === "desktop"
                          ? "3px solid #5bbf6d"
                          : "2px solid transparent",
                    }}
                    onClick={() => {
                      setDeviceTypeFilter("desktop");
                    }}
                  >
                    <FontAwesomeIcon icon={faGlobe} /> Web
                  </button>
                  <button
                    className={`btn btn-warning pt-2 pb-2 me-2 ${device_type === "ios" ? "active" : ""
                      }`}
                    style={{
                      border:
                        device_type === "ios"
                          ? "3px solid #5bbf6d"
                          : "2px solid transparent",
                    }}
                    onClick={() => {
                      setDeviceTypeFilter("ios");
                    }}
                  >
                    <FontAwesomeIcon icon={faApple} /> iOS
                  </button>
                  <button
                    className={`btn btn-warning pt-2 pb-2 me-2 ${device_type === "android" ? "active" : ""
                      }`}
                    style={{
                      border:
                        device_type === "android"
                          ? "3px solid #5bbf6d"
                          : "2px solid transparent",
                    }}
                    onClick={() => {
                      setDeviceTypeFilter("android");
                    }}
                  >
                    <FontAwesomeIcon icon={faAndroid} /> Android
                  </button>
                  <button
                    className="btn btn-warning pt-2 pb-2 ps-3 pe-3 ms-auto me-2"
                  >
                    <Link to="/mostpopular" style={{ textDecoration: 'none', color: 'black' }}>
                      <i className="fa-solid fa-fire" /> Most Popular
                    </Link>
                  </button>

                </div>
                <div className="row me-3 mt-4">
                  {currentOffers.length === 0 ? (
                    <div className="col-12 text-center">
                      <Empty description="Offers will be available soon. Try another offer" />
                    </div>
                  ) : (
                    currentOffers.map((items, key) => (
                      <div className="col-lg-2 mt-1 mb-1" key={key}>
                        <div className="card game-card p-1">
                          <div className="card-body p-2">
                            <div className="card-image">
                              <img
                                src={items.image_url}
                                className="card-img-top rounded-2"
                                alt="..."
                              />
                            </div>
                            <h5 className="card-title card-text-head text-center">
                              {items.offer_name.slice(0, 12)}
                              {items.offer_name.length > 12 ? "..." : ""}
                            </h5>
                            <h5 className="card-title text-center">
                              Earn {`$${(items.amount / 1000).toFixed(2)}`}
                            </h5>
                            <p className="card-text">
                              {items && items.offer_desc
                                ? items.offer_desc.length > 15
                                  ? items.offer_desc.substring(0, 15) + " ..."
                                  : items.offer_desc
                                : "No description available"}
                            </p>

                            <button
                              className="btn btn-success btn-points w-100"
                              onClick={() =>
                                showModal(
                                  items.offer_url,
                                  items.amount,
                                  items.disclaimer,
                                  items.offer_desc,
                                  items.offer_name
                                )
                              }
                            >
                              <span style={{ fontWeight: 500 }}>
                                +{items.amount}
                              </span>{" "}
                              Points
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  )}
                </div>

                {/* Add Pagination component */}
                <div className="d-flex justify-content-center mt-4">
                  <Pagination
                    count={Math.ceil(offers.length / itemsPerPage)}
                    page={currentPage}
                    onChange={handleChangePage}
                    color="primary"
                    style={{
                      '& .MuiPaginationItem-outlined': {
                        border: '1px solid #3f51b5',
                        color: '#3f51b5',
                        transition: 'background-color 0.3s, color 0.3s',
                        '&:hover': {
                          backgroundColor: '#3f51b5',
                          color: '#fff',
                        },
                      },
                      '.MuiPaginationItem-outlined.Mui-selected': {
                        backgroundColor: '#3f51b5',
                        color: '#fff',
                      },
                      '& .MuiPaginationItem-ellipsis': {
                        backgroundColor: 'transparent',
                        border: 'none',
                        fontSize: '1.2rem',
                      },
                    }}
                  />
                </div>
                <div className="row  mt-4 mb-3">
                  <h2 style={{ fontSize: "24px", fontWeight: "bold" }}>Offer walls </h2>
                  {cardData.map((card) => (
                    <div key={card.id} className="col-lg-2 mb-1">
                      <div className="card game-card p-1" style={{ height: "160px" }}>
                        <div className="card-body p-2">
                          <div style={{}}>
                            <img
                              style={{ width: '150px', height: '80px' }}
                              src={card.imageSrc}
                              className="card-img-top rounded-2"
                              alt="..."
                            />
                          </div>
                          <button
                            className="btn btn-success btn-points mt-2 w-100"
                            onClick={() => FetchOffersCardBtn(card.key, card.href)}
                          >
                            View Offers
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        title="Offer Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        style={{ maxWidth: "400px" }}
      >
        <div style={{ marginBottom: "16px" }}>
          <h2 style={{ fontSize: "24px", fontWeight: "bold", margin: "0" }}>
            {offer_name}
          </h2>
          <p style={{ fontSize: "16px", color: "gray", margin: "0" }}>
            {offer_desc}
          </p>
          <p style={{ fontSize: "16px", color: "gray", margin: "0" }}>
            {modaldisclaimer}
          </p>
        </div>
        <div style={{ marginBottom: "16px" }}>
          <span
            style={{ fontSize: "18px", fontWeight: "bold", color: "green" }}
          >
            Earn {`$${(modalAmount / 1000).toFixed(2)}`}
          </span>
        </div>
        <button
          className="btn btn-success w-100"
          style={{
            fontSize: "18px",
            fontWeight: "bold",
            padding: "12px 0",
            backgroundColor: "#4CAF50",
            color: "white",
            border: "none",
            cursor: "pointer",
          }}
          onClick={handleModalRedirect}
        >
          +{modalAmount} Points
        </button>
      </Modal>
    </>
  );
};

export default Dashboard;