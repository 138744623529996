import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation, useNavigate } from "react-router-dom";
import Home from "./Pages/Home";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Mostpopular from './Pages/Dashboard/Mostpopular'
import Signup from "./Pages/Signup";
import SignupSuccess from "./Pages/SignupSuccess";
import TokenVerification from "./Pages/TokenVerification";
import CreateNewPassword from "./Pages/CreateNewPassword";
import ForgotPassword from "./Pages/ForgotPassword";
import CardList from "./components/CardList/CardList";
import OfferToro from "./Pages/OfferToro";
import CpaLead from "./Pages/CpaLead";
import PointsHistory from "./components/PointsHistory";
import Redeem from "./Pages/Redeem/Redeem";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for the user token in localStorage
    const userToken = localStorage.getItem("token");
  
    // Set the isLoggedIn state based on the presence of a token
    setIsLoggedIn(!!userToken);
  }, []);
  
  
  
  return (
    <Routes>
      {isLoggedIn && (
        <Route path="/" element={<Navigate to="/dashboard" />} />
      )}
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      {isLoggedIn && (
        <Route path="/dashboard" element={<Dashboard />} />
      )}
      <Route path="/signup" element={<Signup />} />
      <Route path="/signup/success" element={<SignupSuccess />} />
      <Route path="/register/check" element={<TokenVerification />} />
      <Route path="/user/password/change" element={<CreateNewPassword />} />
      <Route path="/forgot/password" element={<ForgotPassword />} />
      <Route path="/cardlist" element={<CardList />} />
      <Route path="/offertoro" element={<OfferToro />} />
      <Route path="/caplead" element={<CpaLead />} />
      <Route path="/mostpopular" element={<Mostpopular />} />
      <Route path="/history" element={<PointsHistory />} />
      <Route path="/redeem" element={<Redeem />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
  
}

export default App;
