import React, {useState} from "react";
import "./Login.scss";
import {Box, Container, Typography, TextField, Button} from "@mui/material";
import IconButton from "@mui/material/IconButton";

import InputAdornment from "@mui/material/InputAdornment";
import {useNavigate} from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import axios from "axios";
import MessageModal from "../../common/MessageModal";
import logo from "./../../assets/logo.png";
import {ReactComponent as GoogleIcon} from "../../assets/google-icon.svg";
import OutlinedInput from "@mui/material/OutlinedInput";
import {LoadingButton} from "@mui/lab";
import {useGoogleLogin} from "@react-oauth/google";

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [isEmailError, setIsEmailError] = useState(false);
    const [isPasswordError, setIsPasswordError] = useState(false);
    const [isErrorModal, setIsErrorModal] = useState(false);
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = React.useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [loading, setLoading] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const isValidEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const checkValidations = () => {
        if (!email || !isValidEmail(email)) {
            setIsEmailError(true);
        }

        if (!password) {
            setIsPasswordError(true);
        } else {
            setIsPasswordError(false);
        }
    };


    const googleLogin = useGoogleLogin({
        onSuccess: async (codeResponse) => {
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/account/google/login/`, {
                    access_token: codeResponse.access_token,
                })
                .then((res) => {
                    localStorage.setItem("token", res.data.data.access);
                    localStorage.setItem("user", JSON.stringify(res.data.data));
                    navigate("/dashboard");
                })
                .catch((err) => {
                    let err_msg = err?.response?.data?.message?.errors?.[0]
                    let non_field_err = err?.response?.data?.message?.non_field_errors?.[0]
                    let message = err_msg || non_field_err || "Something Went Wrong";
                    setErrorMessage(message);
                    setIsErrorModal(true);
                });
        },
        onError: (error) => console.log("Login Failed:", error),
    });


    const submitData = async () => {
        if (email && isValidEmail(email) && password) {
            setLoading(true);
            let userIP = await axios.get(`https://api.ipify.org?format=json`).then(async (response) => {
                return response.data?.ip;
            })
            await axios
                .post(`${process.env.REACT_APP_BASE_URL}/account/login/`, {
                    email: email,
                    password: password,
                    ip: userIP
                })
                .then((res) => {
                    setLoading(false);
                    localStorage.setItem("token", res.data.access);
                    localStorage.setItem("user", JSON.stringify(res.data));
                    navigate("/dashboard");
                    window.location.reload();
                })
                .catch((error) => {
                    setLoading(false);
                    const message =
                        error?.response?.data?.message
                        || error?.response?.data
                        || error.message + ': Possibly the backend is not reachable.'
                    setErrorMessage(message);
                    setIsErrorModal(true);
                });
        }
    };

    return (
        <>
            <Box className="login-wrapper">
                <Container
                    className="login-screen-container"
                    maxWidth="md"
                    sx={{backgroundColor: "white"}}
                >
                    <Box className="login-form-wrapper">
                        <Box className="login-screen-top-logo">
                            <img
                                src={logo}
                                style={{
                                    width: "100px",
                                }}
                                alt=""
                                srcset=""
                            />
                        </Box>
                        <Typography className="login-heading" variant="h4" color="initial">
                            Sign in
                        </Typography>
                        <Typography
                            my={2}
                            variant="body1"
                            className="login-para"
                            color="initial"
                        >
                            Welcome back! Please enter your details.
                        </Typography>
                        <Box mt={3}>
                            <Typography mb={1} variant="body1" color="initial">
                                Email
                            </Typography>
                            <TextField
                                className="login-form-input"
                                id="outlined-basic"
                                label=""
                                value={email}
                                error={isEmailError}
                                helperText={isEmailError ? "Please enter valid email" : ""}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setIsEmailError(false);
                                }}
                                variant="outlined"
                                size="small"
                                placeholder="Enter your email"
                                fullWidth
                            />
                        </Box>
                        <Box mt={3}>
                            <Typography mb={1} variant="body1" color="initial">
                                Password
                            </Typography>
                            <OutlinedInput
                                className="login-form-input"
                                id="outlined-basic"
                                label=""
                                type={showPassword ? "text" : "password"}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff/> : <Visibility/>}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                    setIsPasswordError(false);
                                }}
                                error={isPasswordError}
                                helperText={
                                    isPasswordError ? "Please enter valid password" : ""
                                }
                                variant="outlined"
                                placeholder="Enter your password"
                                size="small"
                                fullWidth
                            />
                        </Box>

                        <Box mt={4}>
                            <LoadingButton
                                className={"login-form-button"}
                                variant="contained"
                                size="medium"
                                onClick={() => {
                                    checkValidations();
                                    submitData();
                                }}
                                loading={loading}
                                fullWidth
                            >
                                Sign in
                            </LoadingButton>
                        </Box>

                        <Box mt={4}>
                            <Button
                                onClick={() => googleLogin()}
                                className="google-login-button"
                                variant="contained"
                                size="medium"
                                fullWidth
                            >
                                <GoogleIcon/> &nbsp; Sign in with Google
                            </Button>
                        </Box>
                        <Typography
                            variant="body1"
                            mt={2}
                            mb={1}
                            className="last-text"
                            color="initial"
                        >
                            Don’t have an account?{" "}
                            <span
                                onClick={() => {
                                    navigate("/signup");
                                }}
                            >
                Sign up
              </span>
                        </Typography>
                        <Typography
                            variant="body1"
                            className="forgot-pass-text"
                            color="initial"
                            onClick={() => {
                                navigate("/forgot/password");
                            }}
                            sx={{cursor: "pointer"}}
                        >
                            Forgot Password
                        </Typography>
                    </Box>
                </Container>

                <MessageModal
                    open={isErrorModal}
                    error
                    onClose={setIsErrorModal}
                    title={"Error"}
                    content={errorMessage}
                />
            </Box>
        </>
    );
};

export default Login;
