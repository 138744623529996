import axios from "axios";
  const baseUrl = process.env.REACT_APP_BASE_URL;
  async function getUserIP() {
    return await axios.get(`https://api.ipify.org?format=json`).then(async (response) => {
      return response.data?.ip;
    });
  }

  function getHeaders() { 
    const accessToken = localStorage.getItem('token');
    return {
      'Authorization': `JWT ${accessToken ? accessToken : ''}`,
    };
  }
  const fetchOffers = async (ip, adNetwork, errorHandler, deviceType) => {
    const headers = getHeaders();
    const queryParams = new URLSearchParams();
    queryParams.append('ip', ip);
    queryParams.append('device_type', deviceType);

    let mapping = {
      'offertoro': 'offer_toro_offers',
      'adgem': 'ad_gem_offers',
      'cpalead': 'cpa_lead_offers',
      'cpagrip': 'cpa_grip_offers',
      'ayetstudios': 'ayet_studios_offers',
      'wannads': 'wannads_offers'
    };
    let resource = mapping[adNetwork];

    const requestUrl = `${baseUrl}/offerwalls/${resource}/?${queryParams.toString()}`;
    try {
      const response = await axios.get(requestUrl, { headers });
      const offers = response.data;

      if (offers.length > 0) {
        let lastItem = offers.pop();
        const message = lastItem ? lastItem['message'] : "";

        if (message) {
          errorHandler(message);
        } else {
          offers.push(lastItem);
        }
        return offers;
      }

      return [];
    } catch (error) {
      errorHandler(error.message);
      return [];
    }
  };


  const fetchPopularOffers = async () => {
    const headers = getHeaders();
    const requestUrl = `${baseUrl}/offerwalls/popular_offers/`;
    return await axios.get(requestUrl, { headers }).then((response) => {
      return response.data;
    });
  }
  const fetchAllOffers = async (ip, adNetwork, errorHandler) => {
    const headers = getHeaders();
    const queryParams = new URLSearchParams();
    queryParams.append('ip', ip);

    let mapping = {
      'offertoro': 'offer_toro_offers',
      'adgem': 'ad_gem_offers',
      'cpalead': 'cpa_lead_offers',
      'cpagrip': 'cpa_grip_offers',
      'ayetstudios': 'ayet_studios_offers',
      'wannads': 'wannads_offers'
    };
    let resource = mapping[adNetwork];

    const requestUrl = `${baseUrl}/offerwalls/${resource}/?${queryParams.toString()}`;
    
    try {
      const response = await axios.get(requestUrl, { headers });
      const offers = response.data;

      if (offers.length > 0) {
        let lastItem = offers.pop();
        const message = lastItem ? lastItem['message'] : "";

        if (message) {
          errorHandler(message);
        } else {
          offers.push(lastItem);
        }
        return offers;
      }

      return [];
    } catch (error) {
      errorHandler(error.message);
      return [];
    }
  }
  const getPointsHistory = () => {
    const headers = getHeaders();
    const requestUrl = `${baseUrl}/account/history/points`;
    return axios.get(requestUrl, { headers })
  }

  const getInvalidCallbacks = () => {
    const headers = getHeaders();
    const requestUrl = `${baseUrl}/account/history/invalid_callbacks`;
    return axios.get(requestUrl, { headers })
  }

  const getUserActivity = () => {
    const headers = getHeaders();
    const requestUrl = `${baseUrl}/account/history/user`;
    return axios.get(requestUrl, { headers })
  }

  // survey for fetchTheoremReachSurvey 
  async function fetchTheoremReachSurvey(apiKey, userId, userIpAddress) {
    try {
      const response = await axios.get(
        `https://api.theoremreach.com/api/publishers/v1/user_details?api_key=${apiKey}&user_id=${userId}&ip=${userIpAddress}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
  export {
    getUserIP,
    fetchOffers,
    fetchPopularOffers, 
    fetchAllOffers,
    getPointsHistory,
    getInvalidCallbacks,
    getUserActivity,
    fetchTheoremReachSurvey
  }