import * as React from "react";
import { styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const SearchContainer = styled("div")({
  position: "relative",
  backgroundColor: "#fff", // Background color
  border: "1px solid #ccc", // Border
  borderRadius: "4px", // Border radius
  padding: "4px",  // Padding
  display: "flex",
  alignItems: "center",
  transition: "background-color 0.2s ease",
  "&:hover": {
    backgroundColor: "#f5f5f5", // Background color on hover
  },
});

const SearchIconWrapper = styled("div")({
  display: "flex",
  alignItems: "center",
  paddingRight: "8px",
  color: "#666", // Search icon color
});

const StyledInputBase = styled(InputBase)({
  color: "#333",
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "16px",
    padding: "0", // Reduced padding
    backgroundColor: "transparent",
  },
  "&:focus": {
    backgroundColor: "#fff",
  },
});

const SearchAppBar = () => {
  return (
    <SearchContainer>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search..."
        inputProps={{ "aria-label": "search" }}
      />
    </SearchContainer>
  );
};

export default SearchAppBar;
