import React, {useState} from 'react';
import {Card as MuiCard, CardContent, CardMedia, Typography, Divider, Chip} from '@mui/material';

import {Button, Modal} from 'antd';
import styles from './card.module.scss'

import {Form} from 'antd';

const Card = ({
                  image_url,
                  offer_name,
                  offer_desc,
                  platform,
                  offer_id,
                  disclaimer,
                  amount,
                  offer_url,
                  call_to_action
              }) => {

    const [form] = Form.useForm();
    const [open, setOpen] = useState(false);
    const handleCancel = () => {
        setOpen(false);
    }
    const handleSubmit = async () => {
        const payload = {
            offer_id: offer_id,
        };
        try {
            form.resetFields();
        } catch (error) {
            // toast.error(error?.response?.data?.message);
        } finally {
            // setCreateLoading(false);
        }
    }
    const renderButtons = () => {
        return (

            <Button size={'large'} key="back"
                    onClick={() => window.open(offer_url, '_blank')}>
                {/*Earn {payout * 100} points*/}
                Earn {amount} points
            </Button>
        )
    }
    return (
        <>
            <MuiCard>
                <CardMedia component="img" height="140" image={image_url} alt={offer_name}/>
                <CardContent>
                    <Typography variant="h5" component="div" style={{marginBottom: '10px'}}>
                        {offer_name}
                    </Typography>
                    <Divider/>
                    <Typography variant="h6" component="div" style={{marginTop: '10px'}}>
                        {offer_desc}
                    </Typography>
                    <Typography variant="h6" component="div" style={{marginTop: '10px'}}>
                        {offer_url}
                    </Typography>
                    <Chip label={platform} variant="outlined" sx={{marginTop: '10px'}}/>
                    <Button size={'large'} key="back"
                            onClick={() =>  setOpen(true)}>
                        Earn {amount} points
                    </Button>
                </CardContent>
            </MuiCard>
            <Modal
                className={styles.createModal}
                centered
                title={offer_name}
                open={open}
                onCancel={handleCancel}
                footer={renderButtons()}
            >
                <Form
                    className={styles.eventForm}
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off"
                    onFinish={handleSubmit}
                >

                    <CardMedia component="img" height="250" image={image_url} alt={offer_name}/>
                    <Typography variant="h5" component="div" style={{marginBottom: '10px'}}>
                        Disclaimer: {disclaimer}
                    </Typography>
                    <Divider/>
                    <Typography variant="h6" component="div" style={{marginTop: '10px'}}>
                        Description: {offer_desc}
                    </Typography>
                    <Typography variant="h5" component="div" style={{marginTop: '10px'}}>
                        Instructions: {call_to_action}
                    </Typography>
                    <Typography variant="h5" component="div" style={{marginTop: '10px'}}>
                        Supported Platforms: {platform}
                    </Typography>
                    <Typography variant="h6" component="div" style={{marginTop: '10px'}}>
                        {offer_url}
                    </Typography>
                </Form>
            </Modal>
        </>
    );
};

export default Card;
